var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-userAdd"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('AddUser')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-card-text', [_c('v-form', {
    ref: "validUserForm"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('firstname'),
      "rules": _vm.nameRules,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.user.firstname,
      callback: function ($$v) {
        _vm.$set(_vm.user, "firstname", $$v);
      },
      expression: "user.firstname"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('lastname'),
      "rules": _vm.nameRules,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.user.lastname,
      callback: function ($$v) {
        _vm.$set(_vm.user, "lastname", $$v);
      },
      expression: "user.lastname"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('email'),
      "rules": _vm.emailRules,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "to": {
        name: 'Users'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "success"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }