<template lang="html" src="./pageUserAdd.template.vue"></template>

<style lang="scss" src="./pageUserAdd.scss"></style>

<script>
import isEmail from 'validator/lib/isEmail';
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageUserAdd.i18n');

export default {
  name: 'PageUserAdd',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      user: new this.$BcmModel.User(),
      loading: false,
      userMessageError: null,
      nameRules: [(value) => !!value || this.$t('nameRules')],
      emailRules: [
        (value) => !!value || this.$t('emailRequired'),
        (value) => (!value ? false : isEmail(value) || this.$t('emailValid')),
      ],
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.validUserForm.validate()) {
        this.loading = true;
        /**
         * ADD USER
         */
        const addUserBody = new this.$BcmModel.AddUserBody(
          this.user.firstname,
          this.user.lastname,
          this.user.email
        );
        this.$apiInstance.addUser(addUserBody).then(
          (data) => {
            this.user = data;
            this.$router.push({name: 'Users'});
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('UserSuccessfullyAdded'));
          },
          (error) => {
            /**
             * ERROR ADD USER
             */
            this.userMessageError = ApiErrorParser.parse(error);
            this.$notification.notify('DANGER',this.$t('userMessageError'));
            this.loading = false;
          }
        );
      }
    },
  },
};
</script>
